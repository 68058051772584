import React from 'react';
import { Route } from 'react-router-dom';

const RouteWithLayout = ({ layout: Layout, component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(matchProps) => (
      <Layout>
        <Component {...matchProps} />
      </Layout>
    )}
  />
);

RouteWithLayout.whyDidYouRender = true;

export default RouteWithLayout;
